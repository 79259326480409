import { createSlice } from "@reduxjs/toolkit";
import { setStorage, getStorage } from "../../utils/Storage";

export const slice = createSlice({
  name: "survey",
  initialState: {
    update: false,
    surveyData: null,
    userResponses: null,
    maxPage: 0,
    errors: {},
    errorAPI: "",
    loading: true,
    kioski: false,
    actionResponse: null,
  },
  reducers: {
    setSurveyData(state, { payload }) {
      state.surveyData = payload;
    },
    setUserReponses(state, { payload }) {
      state.userResponses = payload;
    },
    setErrorAPI(state, { payload }) {
      state.errorAPI = payload;
    },
    setUpdate(state) {
      state.update = !state.update;
    },
    setMaxPage(state, { payload }) {
      return { ...state, maxPage: payload };
    },
    setResponseQuestion(state, { payload }) {
      state.errors = {};
      const { optionSelected, questionPosition } = payload;
      state.surveyData.pages[state.currentPage].questions[
        questionPosition
      ].response = optionSelected;
    },
    setFinishSurvey(state) {
      state.errors = {};
      let permissionToPaginator = true;
      const user = state.userResponses;

      const questions = state.surveyData.pages[user.currentPage].questions;
      questions.forEach((question) => {
        if (
          question.require === true &&
          (user.responses[question.id] === null ||
            user.responses[question.id] === "" ||
            user.responses[question.id] === "undefined" ||
            user.responses[question.id] === undefined)
        ) {
          permissionToPaginator = false;
          state.errors[question.id] = "(*) Esta questão é obrigatória";
        }
      });

      if (permissionToPaginator) {
        let aux = getStorage("data-user", true);
        aux.currentPage += 1;
        setStorage("data-user", aux, true);
      }

      state.update = !state.update;
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setKioskiMode(state, { payload }) {
      state.kioski = payload;
    },
    setActionResponse(state, { payload }) {
      state.actionResponse = payload;
    },
  },
});

export const {
  setSurveyData,
  setMaxPage,
  setResponseQuestion,
  setUserReponses,
  setUpdate,
  setFinishSurvey,
  setErrorAPI,
  setErrors,
  setLoading,
  setKioskiMode,
  setActionResponse,
} = slice.actions;

export const surveyData = (state) => state.survey.surveyData;
export const maxPage = (state) => state.survey.maxPage;
export const errors = (state) => state.survey.errors;
export const userResponses = (state) => state.survey.userResponses;
export const update = (state) => state.survey.update;
export const errorAPI = (state) => state.survey.errorAPI;
export const loading = (state) => state.survey.loading;
export const kioski = (state) => state.survey.kioski;
export const actionResponse = (state) => state.survey.actionResponse;

export default slice.reducer;
