import moment from "moment";

//redux
import {
  setUpdate,
  setErrorAPI,
  setLoading,
  setKioskiMode,
  setActionResponse,
  setSurveyData,
} from "../../store/survey/survey.slice";
import {
  getSurveyAPI,
  getSurveyTestAPI,
  receiveResponseAPI,
  actionDispatchAPI,
  getSurveyTimer,
} from "../../store/survey/survey.api";
import {
  validateQuestionVisibility,
  extractPersistAnswerOnPage,
  checkMergeCodes,
} from "../../pages/Survey/validates";

//utils
import { randomId } from "../../utils/String";
import { shuffleArrayAndFixed } from "../../utils/Array";
import { getStorage, setStorage } from "../../utils/Storage";

const generateResponseId = (surveyid, timer) => {
  const surveyData = getStorage(surveyid, true);

  //Se cálculo de dias for mais que o timer, gera um id novo
  const dayTime = surveyData.time;
  const dayTimeNow = moment().unix();

  if (dayTimeNow - dayTime > timer) {
    setStorage(
      surveyid,
      {
        ...surveyData,
        time: moment().unix(),
      },
      true
    );
    return randomId();
  }

  //SE NÃO MUDAR O DIA, SEGUE O FLUXO PADRÃO
  const user = surveyData.user;
  const survey = surveyData.survey;

  if (survey) {
    if (user && user.linkid && user.linkid !== surveyid) {
      setStorage(
        surveyid,
        {
          ...surveyData,
          time: moment().unix(),
        },
        true
      );
      return randomId();
    } else {
      return user.id;
    }
  } else {
    return randomId();
  }
};

const shuffleCheckBoxAndRadioBox = (survey) => {
  survey.pages.map((page) => {
    page.questions.map((quest) => {
      if (quest.type === "checkbox" || quest.type === "radiobox") {
        quest.options = shuffleArrayAndFixed(quest.options);
      }
      return quest;
    });
    return page;
  });
  return survey;
};

const setUrlInformation = (urls) => {
  let urlsCopy = { ...urls };

  const queryString = window.location.search;
  let qReplace = queryString.replace("?", "");
  qReplace = qReplace.replace("%20", " "); //remove espaço da url
  const qReplaceSplit = qReplace.split("&");

  qReplaceSplit.forEach((param) => {
    const paramSplit = param.split("=");
    urlsCopy[paramSplit[0]] = paramSplit[1];
  });

  return urlsCopy;
};

export const initDataSurvey = ({ surveyid, responseid, npsQuery }) => {
  return (dispatch) => {
    const getTimer = getSurveyTimer(surveyid);

    getTimer
      .then((dataTimer) => {
        const { timer } = dataTimer;

        const responseIdGenerated =
          responseid === "" || responseid === undefined || responseid === null
            ? generateResponseId(surveyid, timer)
            : responseid;

        setTimeout(() => {
          const path = window.location.pathname;
          const urlToAPI =
            path.indexOf("test") >= 0
              ? getSurveyTestAPI({
                  npsQuery,
                  surveyid,
                  id: responseIdGenerated,
                })
              : getSurveyAPI({ npsQuery, surveyid, id: responseIdGenerated });

          urlToAPI
            .then((dataAPI) => {
              let { status, message, responses: dataUser, survey } = dataAPI;

              // Se a pesquisa exisitir
              if (status) {
                const surveyData = getStorage(surveyid, true);

                let {
                  id,
                  responses,
                  currentPage,
                  progress,
                  created_at,
                  url,
                  contact,
                  linkid,
                  test,
                } = dataUser;

                const rebuildDefaultEnviroment = () => {
                  const surveyData = getStorage(surveyid, true);
                  let currentPg = surveyData.user.currentPage;

                  const validateActiveCurrentPage = () => {
                    if (
                      surveyData.survey.pages[currentPg] &&
                      !surveyData.survey.pages[currentPg].active
                    ) {
                      currentPg++;
                      validateActiveCurrentPage();
                    }
                  };

                  validateActiveCurrentPage();
                  surveyData.user.currentPage = currentPg;

                  dispatch(setSurveyData(surveyData.survey));
                  setStorage(surveyid, surveyData, true);
                };

                //adiciona js ou css de links terceiros no <head> do site
                if (survey.design?.scriptsHead || survey.design?.stylesHead) {
                  let scriptsHead = survey.design?.scriptsHead;
                  let stylesHead = survey.design?.stylesHead;

                  if (scriptsHead && scriptsHead.length > 0) {
                    let scriptTag = document.createElement("script");
                    scriptsHead.forEach((scriptLink) => {
                      scriptTag.src = scriptLink;
                      scriptTag.type = "text/javascript";
                      document.head.appendChild(scriptTag);
                    });
                  }

                  if (stylesHead && stylesHead.length > 0) {
                    let styleTag = document.createElement("link");
                    stylesHead.forEach((styleLink) => {
                      styleTag.href = styleLink;
                      styleTag.rel = "stylesheet";
                      document.head.appendChild(styleTag);
                    });
                  }
                }

                //adiciona js ou css puro no <head> do site
                if (survey.design?.scripts || survey.design?.styles) {
                  let hasInovyoScripts =
                    document.getElementById("inovyo-scripts");
                  let hasInovyoStyles =
                    document.getElementById("inovyo-styles");
                  if (!hasInovyoScripts && survey.design?.scripts) {
                    let script = document.createElement("script");
                    script.innerHTML = survey.design.scripts;
                    script.id = "inovyo-scripts";
                    script.type = "text/javascript";
                    document.head.appendChild(script);
                  }
                  if (!hasInovyoStyles && survey.design?.styles) {
                    let style = document.createElement("style");
                    style.innerHTML = survey.design.styles;
                    style.innerHTML = style.innerHTML.replaceAll("\n", "");
                    style.innerHTML = style.innerHTML.replaceAll("\n\t", "");
                    style.innerHTML = style.innerHTML.replaceAll("\n\r", "");
                    style.id = "inovyo-styles";
                    document.head.appendChild(style);
                  }
                }

                survey = shuffleCheckBoxAndRadioBox(survey);

                //define os itens estáticos de url
                url = setUrlInformation(url);

                const dataUserJson = {
                  id,
                  responses,
                  currentPage,
                  progress,
                  created_at,
                  linkid,
                  url,
                  contact,
                  test,
                };

                setStorage(
                  surveyid,
                  {
                    survey,
                    errors: "",
                    inputs: {},
                    user: dataUserJson,
                    time: surveyData.time,
                  },
                  true
                );

                if (
                  url.__kioski &&
                  url.__kioski !== "" &&
                  url.__kioski === "on"
                ) {
                  let { kioski } = survey;
                  if (kioski) {
                    dispatch(setKioskiMode(true));
                  }
                }

                setTimeout(() => {
                  validateQuestionVisibility(surveyid);
                  // esse zero abaixo vai causar bug caso exista um lógica que não mostra a página 1, achar uma solução
                  extractPersistAnswerOnPage(surveyid, 0);
                  checkMergeCodes(surveyid);
                  rebuildDefaultEnviroment();
                  dispatch(setUpdate());
                  dispatch(setLoading(false));
                }, 1000);
              } else {
                dispatch(setErrorAPI(message));
                dispatch(setLoading(false));
              }
            })
            .catch(() => {
              dispatch(
                setErrorAPI(
                  "Você está sem internet ou houve uma falha de comunicação!"
                )
              );
              dispatch(setLoading(false));
            });
        }, 1000);
      })
      .catch(() => {
        dispatch(
          setErrorAPI(
            "Você está sem internet ou houve uma falha de comunicação!"
          )
        );
        dispatch(setLoading(false));
      });
  };
};

export const sendDataToDB = (dataToSend) => {
  return () => {
    receiveResponseAPI(dataToSend);
  };
};

/**
 * Analisa se a questão respondida tem lógica, se houver atualiza para exibir a lógica
 * @param {Number} questionId Id da questão
 * @returns
 */
export const checkIfUpdate = (surveyid, questionId) => {
  return (dispatch) => {
    let surveyData = getStorage(surveyid, true);
    let existLogic = false;

    const deepLogic = (el) => {
      if (el.lvl === 0) {
        if (el.element_1.value === questionId) {
          return true;
        } else {
          return false;
        }
      } else {
        const res1 = deepLogic(el.element_1);
        if (res1) {
          return true;
        }
        const res2 = deepLogic(el.element_2);
        if (res2) {
          return true;
        } else {
          return false;
        }
      }
    };

    for (var i in surveyData.survey.logics.questions) {
      const itemLogic = surveyData.survey.logics.questions[i];
      if (Object.keys(itemLogic).length > 0 && deepLogic(itemLogic)) {
        existLogic = true;
      }
    }

    if (existLogic) {
      validateQuestionVisibility(surveyid);
      dispatch(setUpdate());
    }
  };
};

export const actionDispatch = (dataAction) => {
  return (dispatch) => {
    actionDispatchAPI(dataAction)
      .then((data) => {
        dispatch(setActionResponse(data.response));
      })
      .catch(() => {
        dispatch(setActionResponse(false));
      });
  };
};
