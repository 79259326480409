/**
 * Gera uma string aleatoria
 * @param {string} qtd - quantidade de caracteres a serem retornados
 * @returns
 */
export const randomId = (qtd) => {
  qtd = qtd || 10;

  var result = "";
  var characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < qtd; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Descobre se um hexadecimal é escuro ou claro, com base na sua luminosidade
 * @param {string} hexadecimal - (String) #000000
 * @returns {string}
 */
export const getLuminosity = (hexadecimal) => {
  var nib = hexadecimal.split("");
  var r = parseInt(nib[1] + nib[2], 16);
  var g = parseInt(nib[3] + nib[4], 16);
  var b = parseInt(nib[5] + nib[6], 16);
  var luminosity = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosity < 127.5 ? "dark" : "light";
};

/**
 * Valida um CPF. A fonte do código é do site da receita federal
 * @param {String} strCPF
 * @returns {boolean}
 */
export const validateCPF = (strCPF) => {
  strCPF = strCPF.replaceAll(".", "").replace("-", "");

  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};
